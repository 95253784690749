import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import { Modal, Button, Container, Form, Header } from 'semantic-ui-react';

import RoleToggle from './role.toggle';
import Validate from '../../../util/validations';

class NewUserModal extends Component {
  ui = observable({
    open: false
  });

  draft = observable({
    // first_name: '',
    // last_name: '',
    email: '',
    roles: { admin: false, coach: true }
  });

  resetDraft = () => {
    this.draft.email = '';
    this.draft.roles = { admin: false, coach: true };
  };

  open = () => {
    this.ui.open = true;
  };

  close = (e, { action }) => {
    this.ui.open = false;
    if (action === 'send') {
      this.props.sendInvitation(this.draft.email);
    }
    this.resetDraft();
  };

  handleChange = (e, { name, value }) => {
    if (
      name !== undefined &&
      name !== null &&
      value !== undefined &&
      value !== null &&
      this.draft[name] !== undefined
    ) {
      this.draft[name] = value === null ? '' : value;
    }
  };

  toggleRole = e => {
    let role = e.target.attributes.role.value;
    if (role && this.draft.roles[role] !== undefined) {
      this.draft.roles[role] = !this.draft.roles[role];
    }
  };

  isValid() {
    return (
      // this.draft.first_name &&
      // this.draft.last_name &&
      Validate.email(this.draft.email)
    );
  }

  render() {
    return (
      <Modal
        trigger={
          this.props.children ? (
            this.props.children
          ) : (
            <Button basic icon="trash" />
          )
        }
        open={this.ui.open}
        onOpen={this.open}
        onClose={this.close}
        closeOnDimmerClick
        closeOnEscape
        closeIcon
        dimmer="blurring"
        centered={true}
        size="small"
      >
        <Modal.Header>Invite User</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Container style={{ marginBottom: '1em' }}>
              <Form>
                <Form.Group widths="equal">
                  {/* <Form.Input
                    fluid
                    label="First name"
                    placeholder="First name"
                    name="first_name"
                    value={this.draft.first_name}
                    onChange={this.handleChange}
                    required
                  />
                  <Form.Input
                    fluid
                    label="Last name"
                    placeholder="Last name"
                    name="last_name"
                    value={this.draft.last_name}
                    onChange={this.handleChange}
                    required
                  /> */}
                  <Form.Input
                    fluid
                    label="Email"
                    placeholder="Email"
                    name="email"
                    value={this.draft.email}
                    onChange={this.handleChange}
                    required
                  />
                </Form.Group>
                <Header size="tiny" dividing>
                  Roles
                </Header>
                <Form.Group>
                  <Form.Field inline style={{ marginRight: '1.5em' }}>
                    <RoleToggle
                      userRole="admin"
                      on={this.draft.roles.admin}
                      label="Admin"
                      color="yellow"
                      toggle={this.toggleRole}
                      style={{ marginRight: '0.3em' }}
                    />
                  </Form.Field>
                  <Form.Field inline>
                    <RoleToggle
                      userRole="coach"
                      on={this.draft.roles.coach}
                      label="Coach"
                      toggle={this.toggleRole}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Container>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.close} action="cancel">
            Never mind
          </Button>
          <Button
            primary
            onClick={this.close}
            action="send"
            disabled={!this.isValid()}
          >
            Send invitation
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default observer(NewUserModal);
