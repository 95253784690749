import { observable } from 'mobx';
import firebase from 'firebase';

import * as moment from 'moment';

var authStore = observable({
  init() {
    firebase.auth().onAuthStateChanged(user => {
      this.user = user;
    });
  },

  signIn(email, password) {
    if (this.authUser) {
      return Promise.resolve(this.authUser);
    }
    return firebase.auth().signInWithEmailAndPassword(email, password);
  },

  signOut() {
    firebase.auth().signOut();
  },

  get initialising() {
    return this.user === undefined;
  },

  get ready() {
    return !this.initialising;
  },

  get isSignedIn() {
    return !this.initialising && this.user !== null;
  },

  get isSignInWithEmailLink() {
    return firebase.auth().isSignInWithEmailLink(window.location.href);
  },

  signInWithEmailLink(email) {
    console.log(email);
    // The client SDK will parse the code from the link for you.
    firebase
      .auth()
      .signInWithEmailLink(email, window.location.href)
      .then(function(result) {
        // Clear email from storage.
        window.localStorage.removeItem('emailForSignIn');
        // You can access the new user via result.user
        // Additional user info profile not available via:
        // result.additionalUserInfo.profile == null
        // You can check if the user is new or existing:
        // result.additionalUserInfo.isNewUser
        console.log(result);
      })
      .catch(function(error) {
        // Some error occurred, you can inspect the code: error.code
        // Common errors could be invalid email and invalid or expired OTPs.
        console.log(error);
      });
  },

  get profile() {
    return this.user
      ? {
          uid: this.user.uid,
          email: this.user.email,
          displayName: this.user.displayName,
          photoURL: this.user.photoURL,
          createdAt: moment(this.user.metadata.creationTime),
          lastSignInTime: moment(this.user.metadata.lastSignInTime)
        }
      : null;
  }
});
authStore.init();

export default authStore;
