import { observable } from 'mobx';
import firebase from 'firebase';

// User app settings (excluding module specific settings)
var settingsStore = observable({
  settingsRef: null,
  closeDBSession: null,

  loading: true,

  subscribe(user) {
    if (this.closeDBSession) this.unsubscribe();
    this.settingsRef = firebase
      .firestore()
      .collection('settings')
      .doc(user.uid);
    this.closeDBSession = this.settingsRef.onSnapshot(
      function(doc) {
        // const data = doc.data();
        //XXX need to handle case where user doesn't yet exist in the settings db ==> ie doc.data() returns undefinec
        this.loading = false;
      }.bind(this),
      function(error) {
        console.error('Error accessing settings: ' + error.message);
      }
    );
  },

  unsubscribe() {
    if (this.closeDBSession) {
      this.closeDBSession();
    }
    this.settingsRef = null;
    this.closeDBSession = null;
    this.loading = true;
  }
});

export default settingsStore;
