import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { Message, Segment, Header, Label, Icon, List } from 'semantic-ui-react';

import ProfileHeader from '../../users/profile.header';
import RolesSettingsTable from './roles.settings.table';
import ModulesSettingsPanel from '../../../components/settings/modules.settings.panel';

class AccountSettingsPanel extends Component {
  render() {
    const { auth, clubs } = this.props.stores;
    const { profile } = auth;
    const { club } = clubs;

    if (!profile) return <Message header="Error loading profile" />;

    let roleLabels = [];
    if (clubs.isAdmin) {
      roleLabels.push(
        <Label key="admin">
          <Icon name="key" />
          Admin
        </Label>
      );
    }
    if (clubs.isCoach && clubs.squads.length === 0) {
      roleLabels.push(
        <Label key="coach">
          <Icon name="stopwatch" />
          Coach
        </Label>
      );
    }
    if (clubs.isCoach && clubs.squads.length > 0) {
      clubs.squads.forEach(tag =>
        roleLabels.push(
          <Label key={'coach-' + tag}>
            <Icon name="stopwatch" />
            Coach
            <Label.Detail>{tag}</Label.Detail>
          </Label>
        )
      );
    }

    return (
      <div>
        <Segment.Group style={{ margin: '2rem 0' }}>
          <Segment secondary>
            <Header>Profile</Header>
          </Segment>
          <Segment>
            <ProfileHeader profile={profile} />
          </Segment>
        </Segment.Group>
        {club && (
          <Segment.Group style={{ margin: '2rem 0' }}>
            <Segment secondary>
              <Header>{club.name ? club.name : 'Club'}</Header>
            </Segment>
            <Segment>
              <List style={{ marginBottom: '2em' }}>
                {club.description && (
                  <List.Item>
                    <List.Header>Description</List.Header>
                    {club.description}
                  </List.Item>
                )}
                {club.address && (
                  <List.Item>
                    <List.Header>Address</List.Header>
                    {club.address}
                  </List.Item>
                )}
                {club.phone && (
                  <List.Item>
                    <List.Header>Phone</List.Header>
                    {club.phone}
                  </List.Item>
                )}
                {club.email && (
                  <List.Item>
                    <List.Header>Email</List.Header>
                    {club.email}
                  </List.Item>
                )}
                {club.web && (
                  <List.Item>
                    <List.Header>Web</List.Header>
                    {club.web}
                  </List.Item>
                )}
              </List>
              {!clubs.isAdmin && roleLabels.length > 0 && (
                <div>
                  <Header style={{ marginBottom: '0.2rem' }}>
                    <Header.Subheader>
                      Your role{roleLabels.length > 1 && <span>s</span>}
                    </Header.Subheader>
                  </Header>
                  {roleLabels}
                </div>
              )}
              {clubs.isAdmin && <RolesSettingsTable />}
            </Segment>
          </Segment.Group>
        )}

        {club && <ModulesSettingsPanel />}
      </div>
    );
  }
}

export default inject('stores')(observer(AccountSettingsPanel));
