import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { Table, Icon, Button } from 'semantic-ui-react';

import RoleToggle from './role.toggle';
import NewUserModal from './new.user.modal';

class RolesSettingsTable extends Component {
  toggleRole = e => {
    let user = e.target.attributes.user.value;
    let role = e.target.attributes.role.value;
    const { clubs } = this.props.stores;
    clubs.toggleRoleEnabled(user, role);
  };

  sendInvitation(email) {
    const { clubs } = this.props.stores;
    clubs.inviteUser(email, clubs.activeClub);
  }

  render() {
    const { auth, clubs } = this.props.stores;

    let userList = clubs.userIDs.filter(user => user !== auth.user.uid);
    userList.unshift(auth.user.uid);
    let userRows = userList.map(userID => {
      let user = clubs.userDetails(userID);
      let name = user ? user.first_name + ' ' + user.last_name : 'Unknown user';
      return (
        <Table.Row
          key={userID}
          positive={userID === auth.user.uid ? true : null}
        >
          <Table.Cell>
            {name}
            {userID === auth.user.uid && (
              <span style={{ color: 'lightgrey', marginLeft: '0.3em' }}>
                (You)
              </span>
            )}
          </Table.Cell>
          <Table.Cell collapsing>
            <RoleToggle
              userID={userID}
              userRole="admin"
              on={clubs.hasRole('admin', userID)}
              disabled={userID === auth.user.uid}
              color="yellow"
              toggle={this.toggleRole}
            />
          </Table.Cell>
          <Table.Cell collapsing textAlign="center">
            <RoleToggle
              userID={userID}
              userRole="coach"
              on={clubs.hasRole('coach', userID)}
              toggle={this.toggleRole}
            />
          </Table.Cell>
        </Table.Row>
      );
    });

    return (
      <Table compact unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>User Accounts</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Admin</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Coach</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{userRows}</Table.Body>
        <Table.Footer fullWidth>
          <Table.Row>
            <Table.HeaderCell colSpan="3">
              <NewUserModal sendInvitation={this.sendInvitation.bind(this)}>
                <Button
                  floated="right"
                  icon
                  labelPosition="left"
                  primary
                  size="small"
                >
                  <Icon name="user" /> Invite User
                </Button>
              </NewUserModal>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    );
  }
}

export default inject('stores')(observer(RolesSettingsTable));
