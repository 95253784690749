//XXX this approach will embed all configs in the client scripts, should find a cleaner option

const production = {
  app: {
    title: 'Trackletic',
    url: 'https://app.trackletic.com'
  },
  server: {
    apiKey: 'AIzaSyA2F5Rw8u-e2sLB_QUE4SJ4IYzq7AOMHq0',
    authDomain: 'trackletic-app.firebaseapp.com',
    databaseURL: 'https://trackletic-app.firebaseio.com',
    projectId: 'trackletic-app',
    storageBucket: 'trackletic-app.appspot.com',
    messagingSenderId: '209525514773'
  },
  firestore: {}
};

const staging = {
  app: {
    title: 'Trackletic Staging',
    url: 'https://staging.trackletic.dev'
  },
  server: {
    apiKey: 'AIzaSyATKOW507nW-Ee7k0TBTyZnVWcC0k7c12o',
    authDomain: 'trackletic-staging.firebaseapp.com',
    databaseURL: 'https://trackletic-staging.firebaseio.com',
    projectId: 'trackletic-staging',
    storageBucket: 'trackletic-staging.appspot.com',
    messagingSenderId: '500837645406'
  },
  firestore: {}
};

const dev = {
  app: {
    title: 'Lane 1 - Trackletic Dev',
    url: 'https://lane1.trackletic.dev'
  },
  server: {
    apiKey: 'AIzaSyD0PZeXiQTBSx9n2vrsiENC1_-7VELHrGk',
    authDomain: 'trackletic-dev-1.firebaseapp.com',
    databaseURL: 'https://trackletic-dev-1.firebaseio.com',
    projectId: 'trackletic-dev-1',
    storageBucket: 'trackletic-dev-1.appspot.com',
    messagingSenderId: '652495928039'
  },
  firestore: {}
};

const config =
  process.env.REACT_APP_STAGE === 'production'
    ? production
    : process.env.REACT_APP_STAGE === 'staging'
    ? staging
    : process.env.REACT_APP_STAGE === 'dev-lane-1'
    ? dev
    : dev;

export default {
  // Add common config values here
  // param: 'value',
  ...config
};
