import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { Icon } from 'semantic-ui-react';

class RoleToggle extends Component {
  render() {
    const { userID, userRole, on, label, disabled, color, toggle } = this.props;
    let iconColor = on ? (color ? color : 'blue') : 'grey';

    let icon = (
      <Icon
        link={!disabled}
        name="toggle on"
        color={iconColor}
        style={
          on
            ? { marginLeft: '0.3em', marginRight: '0.3em' }
            : {
                transform: 'scaleX(-1)',
                marginLeft: '0.3em',
                marginRight: '0.3em'
              }
        }
        size="big"
        onClick={!disabled ? toggle : null}
        user={userID}
        role={userRole}
      />
    );

    if (label) {
      return (
        <span>
          {icon}
          <label>{label}</label>
        </span>
      );
    }
    return icon;
  }
}

export default inject('stores')(observer(RoleToggle));
