import React from 'react';
import { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import { Segment, Button, Form } from 'semantic-ui-react';

import Validate from '../util/validations';

class SignupPage extends Component {
  draft = observable({
    email: ''
  });

  handleChange = (e, { name, value }) => {
    this.draft[name] = value === null ? '' : value;
  };

  isValid() {
    return Validate.email(this.draft.email);
  }

  signUp() {
    if (this.isValid()) {
      const { auth } = this.props;
      auth.signInWithEmailLink(this.draft.email);
    }
  }

  render() {
    let email = window.localStorage.getItem('emailForSignIn');
    if (!email) {
      // User opened the link on a different device. To prevent session fixation
      // attacks, ask the user to provide the associated email again. For example:
      // email = window.prompt('Please provide your email for confirmation');
      email = '';
    }

    // // Confirm the link is a sign-in with email link.
    // if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
    //   // Additional state parameters can also be passed via URL.
    //   // This can be used to continue the user's intended action before triggering
    //   // the sign-in operation.
    //   // Get the email if available. This should be available if the user completes
    //   // the flow on the same device where they started it.
    //   email = window.localStorage.getItem('emailForSignIn');
    //   if (!email) {
    //     // User opened the link on a different device. To prevent session fixation
    //     // attacks, ask the user to provide the associated email again. For example:
    //     // email = window.prompt('Please provide your email for confirmation');
    //     email = '';
    //   }
    //   // The client SDK will parse the code from the link for you.
    //   firebase
    //     .auth()
    //     .signInWithEmailLink(email, window.location.href)
    //     .then(function(result) {
    //       // Clear email from storage.
    //       window.localStorage.removeItem('emailForSignIn');
    //       // You can access the new user via result.user
    //       // Additional user info profile not available via:
    //       // result.additionalUserInfo.profile == null
    //       // You can check if the user is new or existing:
    //       // result.additionalUserInfo.isNewUser
    //     })
    //     .catch(function(error) {
    //       // Some error occurred, you can inspect the code: error.code
    //       // Common errors could be invalid email and invalid or expired OTPs.
    //     });
    // }

    return (
      <div>
        <Segment textAlign="center">
          <div style={{ fontSize: '1.2em', marginBottom: '1em' }}>
            <p>You have been invited to join Trackletic</p>
            <p>Please confirm your email address</p>
            <Form>
              <Form.Input
                fluid
                placeholder="Email"
                name="email"
                value={this.draft.email}
                onChange={this.handleChange}
                required
              />
            </Form>
          </div>
          <Button
            primary
            onClick={this.signUp.bind(this)}
            disabled={!this.isValid()}
          >
            Continue
          </Button>
        </Segment>
      </div>
    );
  }
}

export default observer(SignupPage);
